<template>
    <Navbar page="auctions"/>
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
            <input type="text" class="form-control my-3" style="width:200px;" placeholder="Search" v-model="search"/>
            <div>
                <button class="btn btn-primary" @click="show_new_auction=true">New Auction</button>
            </div>
        </div>

        <table class="table table-striped table-hover">
        <thead>
        <tr>
            <th class="shrink">Auction ID</th>
            <th class="shrink">Date</th>
            <th>Description</th>
            <th class="shrink" colspan="5"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="auction in filteredAuctions" :key="auction.id" @click="selectAuction(auction)">
            <td class="shrink">{{auction.id}}</td>
            <td class="shrink">{{!auction.date ? 'Not set' : auction.date}}</td>
            <td>{{auction.description}}</td>
            <td class="shrink text-end">
                <button class="btn btn-sm btn-link" @click.stop="$router.push('/catalogue/'+auction.id)">Public Catalogue</button>
            </td>
            <td class="shrink text-end">
                <button class="btn btn-sm btn-link" @click.stop="$router.push('/auction/print/'+auction.id)">Print</button>
            </td>
            <td class="shrink text-end">
                <button class="btn btn-sm btn-link" @click.stop="$router.push('/lots/auction/'+auction.id)">{{auction.lots}} Lot(s)</button>
            </td>
            <td class="shrink text-end">
                <button class="btn btn-sm btn-link" @click.stop="$router.push('/seller_invoices/'+auction.id)">Seller Invoices</button>
            </td>
            <td class="shrink text-end">
                <button class="btn btn-sm btn-link" @click.stop="$router.push('/buyer_invoices/'+auction.id)">Buyer Invoices</button>
            </td>
        </tr>
        </tbody>
        </table>
    </div>

    <Modal :show="selected_auction!=null" title="Update Auction" :width="400">
        <Input class="mb-1" placeholder="Date" label="Date" v-model="selected_auction.date"/>
        <Input class="mb-1" placeholder="Description" label="Description" v-model="selected_auction.description"/>

        <template v-slot:footer>
            <div class="d-flex justify-content-between">
                <button @click="deleteAuction()" class="btn btn-sm btn-danger me-2">Delete</button>
                <div> 
                    <button @click="selected_auction=null" class="btn btn-sm btn-secondary me-2">Cancel</button>
                    <button @click="saveChanges()" class="btn btn-sm btn-primary" :disabled="!validToSubmitSelected">Save Changes</button>
                </div>
            </div>
        </template>
    </Modal>

    <Modal :show="show_new_auction" title="New Auction" :width="400">
        <Input class="mb-1" placeholder="Date" label="Date" v-model="new_auction.date"/>
        <Input class="mb-1" placeholder="Description" label="Description" v-model="new_auction.description"/>

        <template v-slot:footer>
            <button @click="show_new_auction=false" class="btn btn-sm btn-secondary me-2">Cancel</button>
            <button @click="saveNewAuction()" class="btn btn-sm btn-primary" :disabled="!validToSubmitNewAuction">Save</button>
        </template>
    </Modal>

    <Modal :show="error!=''" title="Error">
        {{error}}
        <template v-slot:footer>
            <button class="btn btn-secondary btn-sm" @click="error=''">Ok</button>
        </template>
    </Modal>
</template>

<script>
import Navbar from '../components/Navbar'
import _ from 'lodash'
export default{
    name: 'Auctions',
    components:{
        Navbar
    },
    data(){
        return {
            auctions: [],
            search:"",
            selected_auction: null,
            selected_auction_copy: null,
            error: '',
            show_new_auction: false,
            new_auction:{
                date: '',
                description: ''
            }
        }
    },
    computed:{
        filteredAuctions(){
            if(this.search == ""){
                return this.auctions;
            }
            return this.auctions.filter(auction=>{
                var found = false;
                _.forEach(auction, (value)=>{
                    if(!found && value.toString().toLowerCase().includes(this.search.toLowerCase())){
                        found=true;
                    }
                })

                if(found){
                    return true;
                }

                return false;
            });
        },
        validToSubmitSelected(){
            return !_.isEqual(this.selected_auction, this.selected_auction_copy);
        },
        validToSubmitNewAuction(){
            if( this.new_auction.date.trim() == "" || this.new_auction.description.trim() == "" ){
                return false;
            }

            return true;
        },
    },
    methods:{
        getAuctions(){
            this.axios.get(this.apiUrl('auctions'), this.tokenHeader).then(response=>{
                this.auctions = response.data
            });
        },
        deleteAuction(){
            this.axios.delete(this.apiUrl('auction/'+this.selected_auction.id), this.tokenHeader).then(response=>{
                if(response.data.status == "success"){
                    this.getAuctions();
                    this.selected_auction = null;
                    this.selected_auction_copy = null;
                }else{
                    this.error = "Unable to delete auction with lots attached. Consider changing the date and description instead.";
                }
            });
        },
        selectAuction(auction){
            this.selected_auction = _.cloneDeep(auction);
            this.selected_auction_copy = _.cloneDeep(auction);
        },
        saveChanges(){
            var changed = {}
            for(const [key, value] of Object.entries(this.selected_auction)){
                if( !_.isEqual( value, this.selected_auction_copy[key] )){
                    changed[key] = value;
                }
            }
            this.axios.patch(this.apiUrl('auction/'+this.selected_auction.id), changed, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getAuctions();
                    this.selected_auction = null;
                    this.selected_auction_copy = null;
                }else{
                    this.error = "Error saving changes"
                }
            });
        },
        saveNewAuction(){
            this.axios.post(this.apiUrl('auction'), this.new_auction, this.tokenHeader).then(response=>{
                if(response.data.status == 'success'){
                    this.getAuctions();
                    this.show_new_auction = false;
                }else{
                    this.error = "Invalid form entry"
                }
            });
        },
    },
    mounted(){
        this.getAuctions();
    }
}
</script>
