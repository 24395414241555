<template>
    <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created(){
    document.title = "VTP Auctions"
  }
}
</script>

<style>
html,body,#app {
    width:100%;
    height:100%;
}
.shrink{
    width:1px;
    white-space:nowrap;
}
.nav-item.active a{
    color:#fff !important;
}
.table-hover tr{
    cursor:pointer;
}
</style>
