<template>
<div class="p-2">
    <table class="table table-striped table-bordered">
        <thead>
            <tr>
                <th class="shrink">Lot Number</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lot in lots" :key="lot.id">
                <td class="shrink">{{lot.lot_no}}</td>
                <td>{{lot.description}}</td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<style scoped>
table th, table td{
    border:1px solid #000 !important;
}
</style>

<script>
export default {
    name: 'Catalogue',
    props:{
        auction_id: String
    },
    data(){
        return {
            lots: []
        }
    },
    mounted(){
        this.getLots();
    },
    methods:{
        getLots(){
            this.axios.get(this.apiUrl(`catalogue/${this.auction_id}`)).then(response=>{
                this.lots = response.data;
            })
        }
    }
}
</script>
